<template>
  <div class="vap-page">
    <Loading
      id="throbber"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1 :class="{ 'new-custom-folder-name': isNewCustomFolder }">
        {{ folderTitle }}
      </h1>
      <div class="btns-container">
        <VasionButton
          id="save-button"
          :classProp="'primary'"
          :isDisabled="!isDirty"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>

        <VasionButton
          id="cancel-button"
          class="last-btn"
          :classProp="'secondary'"
          @vasionButtonClicked="cancelClick()"
        >
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div id="folder-selection-row" class="setup-row vasion-flex">
        <label id="radio-label" class="vasion-input-label-top">APPLY TO FOLDER</label>
        <div id="radio-selections" class="long-input">
          <md-radio
            id="all-folders-radio"
            v-model="radio"
            class="short-input"
            value="all-folders"
            :disabled="!isNewCustomFolder"
            @change="markAsDirty"
          >
            All Folders
          </md-radio>
          <md-radio
            id="single-folder-radio"
            v-model="radio"
            class="short-input"
            value="single-folder"
            :disabled="!isNewCustomFolder"
            @change="markAsDirty"
          >
            Single Folder
          </md-radio>
        </div>

        <div v-show="radio === 'single-folder'" class="vasion-flex">
          <VasionInput
            id="selected-folder"
            v-model="selectedBrowseFolder"
            title="FOLDER"
            name="selected-folder"
            class="medium-input"
            placeholder="Select Folder..."
            inputType="top-white"
            :readonly="true"
            :required="true"
          />

          <VasionButton
            v-show="isNewCustomFolder"
            id="browse-folders"
            :classProp="'primary'"
            @vasionButtonClicked="() => showBrowseFoldersDialog = !showBrowseFoldersDialog"
          >
            Browse
          </VasionButton>
        </div>
      </div>

      <div class="setup-row subheader-row">
        <h2 class="subheader">
          Columns to Display on Folder View
        </h2>
      </div>

      <div class="setup-row checkbox-selections">
        <VasionCheckbox
          id="checked-out-user"
          name="checked-out-user"
          class="short-input"
          :checked="checkedOutUser"
          @change="toggleCheckbox('checkedOutUser');"
        >
          Checked Out User
        </VasionCheckbox>

        <VasionCheckbox
          id="created-date"
          name="created-date"
          class="short-input"
          :checked="createdDate"
          @change="toggleCheckbox('createdDate');"
        >
          Created Date
        </VasionCheckbox>

        <VasionCheckbox
          id="created-date-time"
          name="created-date-time"
          class="short-input"
          :checked="createdDateTime"
          @change="toggleCheckbox('createdDateTime');"
        >
          Created Date/Time
        </VasionCheckbox>

        <VasionCheckbox
          id="document-count"
          name="document-count"
          class="short-input"
          :checked="documentCount"
          @change="toggleCheckbox('documentCount');"
        >
          Document Count
        </VasionCheckbox>

        <VasionCheckbox
          id="document-name"
          name="document-name"
          class="short-input"
          :checked="documentName"
          @change="toggleCheckbox('documentName');"
        >
          Document Name
        </VasionCheckbox>
      </div>

      <div class="setup-row checkbox-selections">
        <VasionCheckbox
          id="file-size"
          name="file-size"
          class="short-input"
          :checked="fileSize"
          @change="toggleCheckbox('fileSize');"
        >
          File Size
        </VasionCheckbox>

        <VasionCheckbox
          id="folder-name"
          name="folder-name"
          class="short-input"
          :checked="folderName"
          @change="toggleCheckbox('folderName');"
        >
          Folder Name
        </VasionCheckbox>

        <VasionCheckbox
          id="legal-hold"
          name="legal-hold"
          class="short-input"
          :checked="legalHold"
          @change="toggleCheckbox('legalHold');"
        >
          Legal Hold
        </VasionCheckbox>

        <VasionCheckbox
          id="modified-date"
          name="modified-date"
          class="short-input"
          :checked="modifiedDate"
          @change="toggleCheckbox('modifiedDate');"
        >
          Modified Date
        </VasionCheckbox>

        <VasionCheckbox
          id="modified-date-time"
          name="modified-date-time"
          class="short-input"
          :checked="modifiedDateTime"
          @change="toggleCheckbox('modifiedDateTime');"
        >
          Modified Date/Time
        </VasionCheckbox>
      </div>

      <div class="setup-row checkbox-selections">
        <VasionCheckbox
          id="form-name"
          name="form-name"
          class="short-input"
          :checked="formName"
          @change="toggleCheckbox('formName');"
        >
          {{ $formsLabel }} Name
        </VasionCheckbox>

        <VasionCheckbox
          id="uploaded"
          name="uploaded"
          class="short-input"
          :checked="uploaded"
          @change="toggleCheckbox('uploaded');"
        >
          Uploaded
        </VasionCheckbox>

        <VasionCheckbox
          id="user-group"
          name="user-group"
          class="short-input"
          :checked="userGroup"
          @change="toggleCheckbox('userGroup');"
        >
          User / Group
        </VasionCheckbox>

        <VasionCheckbox
          id="workflow-name"
          name="workflow-name"
          class="short-input"
          :checked="workflowName"
          @change="toggleCheckbox('workflowName');"
        >
          Workflow Name
        </VasionCheckbox>
      </div>

      <div class="setup-row checkbox-selections">
        <VasionCheckbox
          id="workflow-status"
          name="workflow-status"
          class="short-input"
          :checked="workflowStatus"
          @change="toggleCheckbox('workflowStatus');"
        >
          Workflow Status
        </VasionCheckbox>
      </div>

      <div class="setup-row subheader-row subheader-row-lower">
        <h2 class="subheader">
          Columns to Display on Report
        </h2>
      </div>

      <div class="setup-row checkbox-selections">
        <VasionCheckbox
          id="created-date-report"
          name="created-date-report"
          class="short-input"
          :checked="createdDateReport"
          @change="toggleCheckbox('createdDateReport');"
        >
          Created Date
        </VasionCheckbox>

        <VasionCheckbox
          id="created-date-time-report"
          name="created-date-time-report"
          class="short-input"
          :checked="createdDateTimeReport"
          @change="toggleCheckbox('createdDateTimeReport');"
        >
          Created Date/Time
        </VasionCheckbox>

        <VasionCheckbox
          id="document-name-report"
          name="document-name-report"
          class="short-input"
          :checked="documentNameReport"
          @change="toggleCheckbox('documentNameReport');"
        >
          Document Name
        </VasionCheckbox>

        <VasionCheckbox
          id="file-size-report"
          name="file-size-report"
          class="short-input"
          :checked="fileSizeInReport"
          @change="toggleCheckbox('fileSizeInReport');"
        >
          File Size
        </VasionCheckbox>

        <VasionCheckbox
          id="folder-name-report"
          name="folder-name-report"
          class="short-input"
          :checked="folderNameReport"
          @change="toggleCheckbox('folderNameReport');"
        >
          Folder Name
        </VasionCheckbox>
      </div>

      <div class="setup-row checkbox-selections">
        <VasionCheckbox
          id="legal-hold-report"
          name="legal-hold-report"
          class="short-input"
          :checked="legalHoldReport"
          @change="toggleCheckbox('legalHoldReport');"
        >
          Legal Hold
        </VasionCheckbox>

        <VasionCheckbox
          id="modified-date-report"
          name="modified-date-report"
          class="short-input"
          :checked="modifiedDateReport"
          @change="toggleCheckbox('modifiedDateReport');"
        >
          Modified Date
        </VasionCheckbox>

        <VasionCheckbox
          id="modified-date-time-report"
          name="modified-date-time-report"
          class="short-input"
          :checked="modifiedDateTimeReport"
          @change="toggleCheckbox('modifiedDateTimeReport');"
        >
          Modified Date/Time
        </VasionCheckbox>

        <VasionCheckbox
          id="user-group-report"
          name="user-group-report"
          class="short-input"
          :checked="userGroupReport"
          @change="toggleCheckbox('userGroupReport');"
        >
          User / Group
        </VasionCheckbox>
      </div>

      <div class="setup-row subheader-row subheader-row-lower">
        <h2 class="subheader">
          Fields
        </h2>
      </div>
      <div v-show="selectedFolder.fields.length > 0" class="setup-row vasion-flex">
        <label class="move-icon-label" />
        <label class="vasion-input-label-top medium-short-input">TITLE *</label>
        <label class="vasion-input-label-top medium-short-input">COLUMN NAME *</label>
        <label class="vasion-input-label-top medium-short-input">FIELD TYPE *</label>
        <label class="vasion-input-label-top short-input">SHOW IN REPORT</label>
      </div>

      <div
        v-for="(field, index) in selectedFolder.fields"
        :key="index"
        class="setup-row vasion-flex"
        :class="{ 'dropTarget': index == dragRowTargetIndex }"
        :value="field.name"
        draggable="true"
        @dragstart="dragRowStart($event, index)"
        @dragover="dragRowOver($event, index)"
        @drop="dragRowDrop($event, index)"
        @dragend="dragRowEnd()"
      >
        <div class="move-icon">
          <VasionMoveIcon />
        </div>
        <div
          class="vasion-flex"
          draggable
          @dragstart="dragRowChildPrevent($event, index)"
        >
          <VasionInput
            v-model="field.displayName"
            name="field-title"
            class="medium-short-input"
            placeholder="Enter Title..."
            inputType="blank-white"
            @input="markAsDirty"
          />
          <VasionDropList
            v-slot="slotItem"
            v-model="field.fieldName"
            name="column-name"
            class="medium-short-input"
            placeholder="Enter Column Name..."
            type="plain-list"
            valueName="value"
            displayName="name"
            :dataArray="uniqueFieldNames"
            @input="markAsDirty"
          >
            {{ slotItem.item.name }}
          </VasionDropList>

          <VasionDropList
            v-slot="slotItem"
            name="field-type"
            class="medium-short-input"
            :dataArray="fieldTypes"
            type="plain-list"
            valueName="id"
            displayName="type"
            :value="calculateFieldTypeId(field.markAsDate, field.markAsNumber)"
            @input="setFieldTypeId(field.displayOrder)"
          >
            {{ slotItem.item.type }}
          </VasionDropList>

          <VasionCheckbox
            :id="field.displayOrder.toString()"
            :name="field.displayOrder.toString()"
            class="show-in-report-checkbox"
            :checked="field.showInReport"
            @change="fieldCheckChange(index); markAsDirty();"
          />
          <VasionButton
            :id="'row-delete_' + field.displayName"
            :name="'row-delete_' + field.displayName"
            class="row-delete-button"
            :icon="'VasionDeleteIcon'"
            title="Delete Row"
            @vasionButtonClicked="deleteRow(field.displayOrder, field.displayName)"
          />
        </div>
      </div>

      <div class="setup-row">
        <VasionButton 
          id="add-field-btn"
          :classProp="'primary'"
          @vasionButtonClicked="addField()"
        >
          Add Field
        </VasionButton>
      </div>

      <div class="setup-row vasion-flex">
        <VasionDropList 
          v-slot="slotItem"
          v-model="sortByField"
          name="sort-field"
          class="medium-short-input"
          placeholder="Order By..."
          title="SORT ORDER"
          type="plain-list"
          valueName="name"
          displayName="name"
          :dataArray="sortableFields"
          @input="markAsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <div id="sort-radios" class="vasion-flex-row">
          <md-radio 
            id="sort-order-asc-radio"
            v-model="sortByAsc" :value="true"
            :disabled="!sortByField || !sortByField.value"
            @change="markAsDirty"
          >
            Ascending
          </md-radio>
          <md-radio 
            id="sort-order-desc-radio"
            v-model="sortByAsc"
            :value="false"
            :disabled="!sortByField || !sortByField.value"
            @change="markAsDirty"
          >
            Descending
          </md-radio>
        </div>
      </div>
    </div>

    <VasionGeneralModal 
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'OK'"
      :confirmButtonDisabled="!folderSelected"
      :modalType="'slot'"
      :sync="showBrowseFoldersDialog"
      @confirmButtonClick="folderDialogOK"
      @noButtonClick="handleCancel"
    >
      <div class="folder-dialog-browse">
        <VasionFolders
          class="tester"
          @itemSelected="handleItemSelected"
        />
      </div>
    </VasionGeneralModal>

    <VasionSnackbar 
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog :message="leaveMessage" @noButtonClick="cancelLeave()" @yesButtonClick="doLeave()" />
    </md-dialog>

    <md-dialog id="confirmFormDelete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteRowAndToggle()" />
    </md-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'CustomFolderViewDetails',
  components: {
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      checkedOutUser: false,
      createdDate: false,
      createdDateTime: false,
      createdDateReport: false,
      createdDateTimeReport: false,
      DEFAULT_SORT_BY: '[Default]',
      deleteRowName: '',
      deleteRowPosition: 0,
      documentCount: false,
      documentName: false,
      documentNameReport: false,
      dragRowStartIndex: -1,
      dragRowTargetIndex: -1,
      fields: [],
      fieldTypes: [
        { type: 'Text', id: 1 },
        { type: 'Date', id: 2 },
        { type: 'Number', id: 3 },
      ],
      fileSize: false,
      fileSizeInReport: false,
      folderId: 0,
      folderName: false,
      folderNameReport: false,
      folderSelected: false,
      folderTitle: '',
      formName: false,
      id: 0,
      isDirty: false,
      isLoading: false,
      isNewCustomFolder: true,
      isRowDataFilled: false,
      legalHold: false,
      legalHoldReport: false,
      loaderColor,
      loaderBackgroundColor,
      modifiedDate: false,
      modifiedDateTime: false,
      modifiedDateReport: false,
      modifiedDateTimeReport: false,
      possibleFolderId: '',
      possibleSelectedBrowseFolder: '',
      radio: 'single-folder',
      selectedBrowseFolder: null,
      selectedFolder: {},
      showBrowseFoldersDialog: false,
      showDeleteDialog: false,
      showInReport: false,
      showLeaveDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: 'Unable To Save Custom Folder',
      sortByField: {},
      sortByAsc: true,
      uploaded: false,
      userGroup: false,
      userGroupReport: false,
      workflowName: false,
      workflowStatus: false,
    }
  },
  computed: {
    deletePromptMessage() { return `Are you sure you to remove "${this.deleteRowName}" from the View?` },
    leaveMessage() { return `Are you sure you want to leave? Changes to "${this.folderTitle}" have not been saved.` },
    sortableFields() {
      let availableFields = [{ name: this.DEFAULT_SORT_BY, value: null }]
      if (this.checkedOutUser) availableFields.push({ name: "Checked Out User", value: "Checked Out" })
      if (this.createdDate) availableFields.push({ name: "Created Date", value: "Created Date" })
      if (this.createdDateTime) availableFields.push({ name: "Created Date/Time", value: "Created Date/Time" })
      if (this.documentCount) availableFields.push({ name: "Document Count", value: "Document Count" })
      if (this.documentName) availableFields.push({ name: "Document Name", value: "DisplayName" })
      if (this.fileSize) availableFields.push({ name: "File Size", value: "File Size" })
      if (this.folderName) availableFields.push({ name: "Folder Name", value: "Folder Path" })
      if (this.legalHold) availableFields.push({ name: "Legal Hold", value: "Legal Hold" })
      if (this.modifiedDate) availableFields.push({ name: "Modified Date", value: "Modified Date" })
      if (this.modifiedDateTime) availableFields.push({ name: "Modified Date/Time", value: "Modified Date/Time" })
      if (this.formName) availableFields.push({ name: "Object Name", value: "Form Name" })
      if (this.uploaded) availableFields.push({ name: "Uploaded", value: "Uploaded" })
      if (this.userGroup) availableFields.push({ name: "User / Group", value: "User/Group" })
      if (this.workflowName) availableFields.push({ name: "Workflow Name", value: "Workflow Name" })
      if (this.workflowStatus) availableFields.push({ name: "Workflow Status", value: "Workflow Status" })

      this.selectedFolder.fields.forEach(field => {
        if (field.displayName) {
          availableFields.push({ name: field.displayName, value: field.displayName })
        }
      })
      return availableFields.sort((a, b) => a.name.localeCompare(b.name));
    },
    uniqueFieldNames() { return this.$store.state.storage.uniqueFieldNames },
  },
  async created() {
    this.isLoading = true

    this.activeFolder = this.$store.state.search.activeFolderView
    this.selectedFolder = JSON.parse(JSON.stringify(this.activeFolder)) // Copy the folder object locally

    this.fields = this.activeFolder.fields
    this.folderId = this.activeFolder.folderID
    this.folderTitle = this.activeFolder.folderName
    this.id = this.activeFolder.id
    this.checkedOutUser = this.activeFolder.showCheckedOutUser
    this.createdDate = this.activeFolder.showCreatedDate
    this.createdDateTime = this.activeFolder.showCreatedDateTime
    this.createdDateReport = this.activeFolder.showCreatedDateInReport
    this.createdDateTimeReport = this.activeFolder.showCreatedDateTimeInReport
    this.documentCount = this.activeFolder.showDocumentCount
    this.documentName = this.activeFolder.showDocumentName
    this.documentNameReport = this.activeFolder.showDocumentNameInReport
    this.fileSize = this.activeFolder.showFileSize
    this.fileSizeInReport = this.activeFolder.showFileSizeInReport
    this.folderName = this.activeFolder.showFolderName
    this.folderNameReport = this.activeFolder.showFolderNameInReport
    this.formName = this.activeFolder.showFormName
    this.legalHold = this.activeFolder.showLegalHold
    this.legalHoldReport = this.activeFolder.showLegalHoldInReport
    this.modifiedDate = this.activeFolder.showModifiedDate
    this.modifiedDateTime = this.activeFolder.showModifiedDateTime
    this.modifiedDateReport = this.activeFolder.showModifiedDateInReport
    this.modifiedDateTimeReport = this.activeFolder.showModifiedDateTimeInReport
    this.uploaded = this.activeFolder.showUploaded
    this.userGroup = this.activeFolder.showUserGroup
    this.userGroupReport = this.activeFolder.showUserGroupInReport
    this.workflowName = this.activeFolder.showWorkflowName
    this.workflowStatus = this.activeFolder.showWorkflowStatus

    if (this.activeFolder.sortColumn) {
      this.sortByField = this.sortableFields.find(field => field.value === this.activeFolder.sortColumn)
      this.sortByAsc = this.activeFolder.sortOrder
    } else {
      this.sortByField = { name: this.DEFAULT_SORT_BY, value: null }
    }

    if (this.folderTitle !== '') {
      this.isNewCustomFolder = false
      if (this.folderTitle !== 'All Folders') {
        this.radio = 'single-folder'
      } else {
        this.radio = 'all-folders'
      }
    } else {
      this.folderTitle = 'Untitled Folder View'
    }

    if (this.selectedBrowseFolder !== this.folderTitle
      && this.folderTitle.length > 0
      && this.folderTitle !== 'Untitled Folder View') {
      this.selectedBrowseFolder = this.folderTitle
    }

    this.isLoading = false
  },
  methods: {
    addField() {
      const newObj = {
        displayName: '',
        displayOrder: this.selectedFolder.fields ? this.selectedFolder.fields.length + 1 : 1,
        fieldName: '',
        markAsDate: false,
        markAsNumber: false,
        showInReport: false,
      }
      if (!this.selectedFolder.fields) {
        this.selectedFolder.fields = []
      }

      this.markAsDirty()

      return this.selectedFolder.fields.push(newObj)
    },
    calculateFieldTypeId(markAsDate, markAsNumber) {
      if (markAsDate) {
        return { id: 2, type: 'Date' }
      } if (markAsNumber) {
        return { id: 3, type: 'Number' }
      }
      return { id: 1, type: 'Text' }
    },
    cancelClick() { this.$router.push({ name: 'CustomFolderViewConfig' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    checkRequiredInputs() {
      this.isDirty = false

      let allChecksGood = true
      let rowTitleMissing = false
      let rowColumnNameMissing = false
      let duplicateName = ''
      let errorText = 'ERROR:'

      if (this.radio === 'single-folder' && (this.selectedBrowseFolder === null || this.selectedBrowseFolder === '')) { // No folder selected while single folder is selected
        allChecksGood = false
        errorText = `${errorText}
        - Must select a "Folder"`
      }

      var displayNames = new Set()
      for (let row = 0; row < this.selectedFolder.fields.length; row += 1) { // Missing row data
        if (this.selectedFolder.fields[row].displayName === '') {
          rowTitleMissing = true
        } else {
          var title = this.selectedFolder.fields[row].displayName.toUpperCase()
          if (displayNames.has(title)) {
            duplicateName = this.selectedFolder.fields[row].displayName
          } else {
            displayNames.add(title)
          }
        }

        if (this.selectedFolder.fields[row].fieldName.name) {
          this.selectedFolder.fields[row].fieldName = this.selectedFolder.fields[row].fieldName.name
        }

        if (!this.selectedFolder.fields[row].fieldName) {
          rowColumnNameMissing = true
        }
      }

      if (rowTitleMissing) {
        allChecksGood = false
        errorText = `${errorText}
        - Must select a Field "Title"`
      }
      if (rowColumnNameMissing) {
        allChecksGood = false
        errorText = `${errorText}
        - Must select a Field "Column Name"`
      }

      if (duplicateName) {
        allChecksGood = false
        errorText = `${errorText}
        - Field Title must be unique: ${duplicateName}`
      }

      if (!this.sortByField?.name || !this.sortableFields.find(field => field.name == this.sortByField?.name)) {
        allChecksGood = false
        this.sortByField = null
        errorText = `${errorText}
        - Must select a "Sort Order"`
      }

      this.snackbarSubTitle = errorText

      if (!allChecksGood) {
        this.isDirty = true
      }

      return allChecksGood
    },
    clickedOutsideLeave() { this.routeTo = null },
    deleteRow(rowPosition, rowName) {
      this.deleteRowPosition = rowPosition - 1
      this.deleteRowName = rowName

      this.toggleDeleteDialog()
    },
    async deleteRowAndToggle() {
      this.selectedFolder.fields.splice(this.deleteRowPosition, 1)

      for (let order = 0; order < this.selectedFolder.fields.length; order += 1) { // Reset order
        this.selectedFolder.fields[order].displayOrder = order + 1
      }

      this.markAsDirty()
      this.toggleDeleteDialog()
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ name: 'CustomFolderViewConfig' })
    },
    dragRowChildPrevent(event) {
      event.preventDefault()
      event.stopPropagation()
    },
    dragRowDrop(event, dropIndex) {
      event.preventDefault();
      const startIndex = this.dragRowStartIndex
      if (startIndex == dropIndex || startIndex + 1 == dropIndex) {
        return
      }

      const movedUp = dropIndex < startIndex
      //if Row moved up, then others have to be moved down (which means increase their field order)
      const displacement = movedUp ? 1 : -1

      // Set the field order for the dragged item to its new order
      this.selectedFolder.fields[startIndex].displayOrder = this.selectedFolder.fields[dropIndex].displayOrder

      // Adjust the field order for the items between the start and drop location
      this.selectedFolder.fields.filter((f, ix) => {
        return (movedUp && ix >= dropIndex && ix < startIndex) || (!movedUp && ix > startIndex && ix <= dropIndex)
      }).forEach((f) => {
        f.fieldOrder += displacement
      })

      // Now move the fields
      if (!movedUp) {
        //if we moved the field down, then once we remove it from the fieldslist it offsets our dropIndex
        dropIndex -= 1
      }
      var element = this.selectedFolder.fields[startIndex];
      this.selectedFolder.fields.splice(startIndex, 1);
      this.selectedFolder.fields.splice(dropIndex, 0, element);
      this.markAsDirty()
    },
    dragRowEnd() {
      this.dragRowTargetIndex = -1
      this.dragRowStartIndex = -1
    },
    dragRowOver(event, dropIndex) {
      if (this.dragRowStartIndex == dropIndex || this.dragRowStartIndex + 1 == dropIndex) {
        return
      }
      event.preventDefault();
      this.dragRowTargetIndex = dropIndex
    },
    dragRowStart(event, index) {
      this.dragRowStartIndex = index;
      event.dataTransfer.effectAllowed = 'move';
    },
    fieldCheckChange(index) { this.selectedFolder.fields[index].showInReport = !this.selectedFolder.fields[index].showInReport },
    folderDialogOK() {
      if (this.folderSelected) {
        this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
        this.folderTitle = this.possibleSelectedBrowseFolder
        this.selectedBrowseFolder = this.possibleSelectedBrowseFolder
        this.folderId = this.possibleFolderId
        this.markAsDirty()
      }
    },
    handleCancel() {
      this.folderSelected = false
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
    },
    handleItemSelected(item) {
      if (item.value === 0) {
        this.folderSelected = false
      } else {
        this.folderSelected = true
        this.possibleSelectedBrowseFolder = item.name
        this.possibleFolderId = item.value
      }
    },
    markAsDirty() { this.isDirty = true; },
    save() {
      const saveSuccess = this.checkRequiredInputs()

      if (!saveSuccess) {
        this.showSnackbar = true
      } else {
        this.activeFolder = {
          fields: this.selectedFolder.fields,
          folderID: this.folderId,
          folderName: this.selectedBrowseFolder,
          id: this.id,
          showCheckedOutUser: this.checkedOutUser,
          showCreatedDate: this.createdDate,
          showCreatedDateTime: this.createdDateTime,
          showCreatedDateInReport: this.createdDateReport,
          showCreatedDateTimeInReport: this.createdDateTimeReport,
          showDocumentCount: this.documentCount,
          showDocumentName: this.documentName,
          showDocumentNameInReport: this.documentNameReport,
          showFileSize: this.fileSize,
          showFileSizeInReport: this.fileSizeInReport,
          showFolderName: this.folderName,
          showFolderNameInReport: this.folderNameReport,
          showFormName: this.formName,
          showLegalHold: this.legalHold,
          showLegalHoldInReport: this.legalHoldReport,
          showModifiedDate: this.modifiedDate,
          showModifiedDateTime: this.modifiedDateTime,
          showModifiedDateInReport: this.modifiedDateReport,
          showModifiedDateTimeInReport: this.modifiedDateTimeReport,
          showUploaded: this.uploaded,
          showUserGroup: this.userGroup,
          showUserGroupInReport: this.userGroupReport,
          showWorkflowName: this.workflowName,
          showWorkflowStatus: this.workflowStatus,
          sortColumn: this.sortByField.value,
          sortOrder: this.sortByField.value ? this.sortByAsc : true,
        }
        this.$store.dispatch('search/saveCustomFolderView', this.activeFolder)
        this.$router.push({ name: 'CustomFolderViewConfig' })
      }
    },
    setFieldTypeId(arrayPosition) {
      setTimeout(() => {
        this.markAsDirty()

        const dropdownValue = document.getElementsByName('field-type')[arrayPosition - 1].value
        let date = false
        let number = false

        if (dropdownValue === 'Date') {
          date = true
          number = false
        } else if (dropdownValue === 'Number') {
          date = false
          number = true
        }
        this.selectedFolder.fields[arrayPosition - 1].markAsDate = date
        this.selectedFolder.fields[arrayPosition - 1].markAsNumber = number
        this.calculateFieldTypeId(date, number)
      }, 100) // Need to wait to grab dropdown value
    },
    toggleCheckbox(id) {
      this[id] = !this[id]

      if (this[id]) {
        switch (id) {
          case 'createdDate':
            this.createdDateTime = false;
            break;
          case 'createdDateTime':
            this.createdDate = false;
            break;
          case 'createdDateReport':
            this.createdDateTimeReport = false;
            break;
          case 'createdDateTimeReport':
            this.createdDateReport = false;
            break;
          case 'modifiedDate':
            this.modifiedDateTime = false;
            break;
          case 'modifiedDateTime':
            this.modifiedDate = false;
            break;
          case 'modifiedDateReport':
            this.modifiedDateTimeReport = false;
            break;
          case 'modifiedDateTimeReport':
            this.modifiedDateReport = false;
            break;
        }
      }

      this.markAsDirty()
    },
    toggleDeleteDialog() { this.showDeleteDialog = !this.showDeleteDialog },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
  },
}
</script>
<style lang="scss">
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';


  

    #title {
      @include Headline;
      float: left;
      height: 40px;
      line-height: 40px;
      vertical-align: middle;
      margin: 0;

      &.new-custom-folder-name {
        color: $grey-400;
      }
    }

    #save-button,
    #cancel-button {
      float: right;

      &.vasion-button {
        button {
          margin-top: 0;
        }
      }
    }

    #save-button {
      margin-right: 10px;
    }
  

  #folder-value-name-row {
    width: 425px !important;
  }

  .setup-row {
    margin-top: 15px;
  }

  .long-input {
    width: 450px !important;
    margin-right: 0px !important;
  }

  .medium-long-input {
    width: 425px !important;
    margin-right: 15px !important;
  }

  .medium-input {
    width: 350px !important;
  }

  .medium-short-input {
    width: 325px !important;
    margin-right: 15px !important;
  }

  .short-input {
    width: 225px !important;
  }

  #radio-label {
    display: flex;
  }

  #radio-selections {
    width: 500px;
    display: inherit;
    padding-top: 15px;
    margin-left: -100px;
  }

  .checkbox-selections {
    width: 100%;
    display: flex;
    padding-top: 15px;
  }

  #browse-folders {
    position: relative;
    top: 16px;
    margin-right: 40px;
  }

  .subheader {
    @include SubHeadline;
    display: block;
    float: left;
    margin-top: 5px;
  }

  .subheader-row {
    height: 35px;
  }

  .subheader-row-lower {
    margin-top: 35px;
  }

  .show-in-report-checkbox {
    margin-top: 8px;
    padding-left: 48px;
  }

  .row-delete-button {
    padding-left: 55px;
  }

  .tester .item .arrowDiv,
  .tester .item .tree-list-item ul {
    display: none;
  }

  .tester .item .tree-list-item {
    padding-left: 22px;
  }

  #sort-radios {
    width: 500px;
    margin-top: 15px;
    margin-left: 20px;
  }

  #sort-radios>.md-radio {
    width: 150px;
  }

  .move-icon {
    cursor: move;
    margin-top: auto;
    margin-bottom: auto;
    fill: $grey-600;
  }

  .move-icon-label {
    width: 24px;
  }

  .dropTarget {
    border-top: 6px solid $secondary;
  }

.md-radio.md-theme-default.md-checked .md-ripple {
  color: $secondary !important;
}

.md-radio.md-theme-default.md-checked .md-radio-container {
  border-color: $secondary !important;
}

.md-radio.md-theme-default.md-checked .md-radio-container:after {
  background-color: $secondary !important;
}
</style>
